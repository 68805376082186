import React from "react";
import { Link } from "react-router-dom";
import BlogDetailsLeftBar from "../components/BlogDetailsLeftBar";
import { IMAGES } from "../constant/theme";

const widgetBlog = [
  {
    title: "New vaccine for cattle calf loss learned.",
    image: IMAGES.Recentblog1 as unknown as string, // Cast to unknown first, then to string
  },
  {
    title: "He Created the Web. Now He’s Out to Remake.",
    image: IMAGES.Recentblog2 as unknown as string,
  },
  { 
    title: "Partnering to create a community.", 
    image: IMAGES.Recentblog3 as unknown as string 
  },
  {
    title: "Best & Less published their supplier lists.",
    image: IMAGES.Recentblog4 as unknown as string,
  },
];



const BlogDetails: React.FC = () => {
  return (
    <>
      <div className="page-content bg-white">
        <section className="content-inner-2">
          <div className="container">
            <div className="row ">
              <div className="col-xl-8 col-lg-8 m-b30">
                <BlogDetailsLeftBar />
              </div>
              <div className="col-xl-4 col-lg-4">
                <aside className="side-bar sticky-top">
                  <div className="widget style-1">
                    <div className="widget-title">
                      <h5 className="title">Search</h5>
                    </div>
                    <div className="search-bx">
                      <form role="search">
                        <div className="input-group">
                          <input
                            name="text"
                            className="form-control"
                            placeholder="Search Here..."
                            type="text"
                          />
                          <span className="input-group-btn">
                            <button
                              type="submit"
                              className="btn btn-primary sharp radius-no"
                            >
                              <i className="la la-search scale3"></i>
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="widget style-1 recent-posts-entry">
                    <div className="widget-title">
                      <h5 className="title">Recent Post</h5>
                    </div>
                    <div className="widget-post-bx">
                      {widgetBlog.map((item, ind) => (
                        <div className="widget-post clearfix" key={ind}>
                          <div className="dz-media">
                            <img src={item.image} alt="Description of the shape" />
                          </div>
                          <div className="dz-info">
                            <h6 className="title">
                              <Link to={"/blog-details"}>{item.title}</Link>
                            </h6>
                            <div className="dz-meta">
                              <ul>
                                <li className="post-date">
                                  <i className="fa-solid fa-calendar-days"></i>{" "}
                                  7 March, 2022
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

               
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogDetails;
